<template>
  <div class="card card-custom card-stretch gutter-b" v-loading="loading">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">お知らせメール一覧</h3>
      </div>
      <div class="card-toolbar">
      </div>
    </div>
    <div class="card-body">
      <el-form
          ref="form"
          :model="form"
          @submit.prevent.native="clickSearch"
      >
        <div class="row">
          <div class="col-md-6" >
            <el-form-item style="display: flex" label="キーワード" prop="keyword" class="search">
              <el-input placeholder="お知らせのタイトル、お知らせの内容" v-model="form.keyword"></el-input>
            </el-form-item>
          </div>

          <div class="col-md-2">
            <el-row style="margin-left:20px">
                <b-button class="d-flex flex-row" size="medium" @click="clickSearch" variant="primary">
                  <b-icon icon="search" class="mr-1"></b-icon>
                  検索
                </b-button>
            </el-row>
          </div>
        </div>
      </el-form>
      <hr />
      <div class="d-flex flex-row justify-content-between">
        <span class="demonstration">
          <my-pagination-top style="padding-left:8px;  padding-bottom:10px"
                             :total-record="parseInt(data.total)"
                             :current-page="parseInt(queryParam.page)"
                             :page-size-default="parseInt(data.per_page)"
                             @handleSizeChange="handleSizeChange"
          />
        </span>

        <router-link class="el-button btn btn-primary el-button--text send-email-all router-link " :class="data.data === '' ? 'hidden' : ''" :to="{name: 'content-email',query :{ flag: 1}}">お知らせメールの送信</router-link>

      </div>
      <el-table
          :fit="true"
          border
          :data="data.data"
          style="width: 100%"
          :row-class-name="tableRowClassName">

        <el-table-column
            prop="id"
            label="お知らせID"
            width="100"
            align="center"
        >
        </el-table-column>

        <el-table-column
            label="メールの件名"
            align="center"
        >
          <template slot-scope="scope">
            <div class="title text-left">
              <span >{{scope.row.title}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            label="お知らせの内容"
            align="center">
          <template slot-scope="scope">
            <div class="content text-left">
              <span >{{scope.row.content}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            prop="send_quantity"
            label="送信人数"
            align="center"
            width="100">
          <template slot-scope="scope">
            <div class="content text-center">
              <span >{{scope.row.send_quantity}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            prop="created"
            label="送信日時"
            align="center">
          <template slot-scope="scope">
            <div class="content text-center">
              <span >{{scope.row.created}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
            width="210"
            align="center"
        >
          <template slot-scope="scope">
            <router-link class="el-button btn btn-primary el-button--text router-link detail-email" :to="{name: 'detail-email', params :{ idNotifi: scope.row.id,}}">詳細</router-link>

            <el-button class="el-button btn btn-primary el-button--text router-link send-email"  @click="getId(scope.row.id)" >コピー</el-button>

            <el-button class="btn btn-primary router-link delete-btn" @click="deleteNotifi(scope.row)" type="text" >削除</el-button>
          </template>
        </el-table-column>

      </el-table>
      <el-dialog
          title="本当に削除しますか？"
          :visible.sync="dialogDelete"
          width="30%"
          align="center"
      >
        <span slot="footer" class="dialog-delete">
          <div class="row">
            <div class="col-md-6 text-right">
                  <el-button style="width: 120px" class="btn-cancel" @click="dialogDelete = false">いいえ</el-button>
            </div>
            <div class="col-md-6 text-left">
                  <el-button style="width: 120px" type="primary" @click="comfirmDeleteNotifi()">はい</el-button>
            </div>
          </div>
        </span>
      </el-dialog>
      <div v-if="data" class="row float-right pr-5 pt-7">
        <my-pagination
            :total-record="parseInt(data.total)"
            :currentPage="parseInt(data.current_page)"
            :page-size-default="parseInt(data.per_page)"
            @handleCurrentChange="handleCurrentChange"
            @handleSizeChange="handleSizeChange"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

import { LIST_NOTIFI, DELETE_NOTIFI } from "@/core/services/store/notifi.module";
import { STATUS_CODE } from "@/core/config/constant";

/* Status code */
const { AUTHENTICATE, ERROR_SERVER, SUCCESS } = STATUS_CODE

export default {
  name: "ListNotifi",
  data() {
    return {
      data: {},
      loading: false,
      dialogDelete: false,
      id:{},
      querySearch: {
        page: "",
      },
      search: "",
      form: {
        keyword: ''
      },
    };
  },

  computed: {
    ...mapGetters(["currentUser"]),
    queryParam() {
      return this.$route.query;
    },
  },

  watch: {
    queryParam() {
      this.getList();
    },
  },

  async created() {
    this.getDataFromQuery(this.form);
    this.getList();
  },

  methods: {
    async getList() {
      this.loading = true;
      let params = this.serialize(this.queryParam);
      let data = await this.$store.dispatch(LIST_NOTIFI, params);
      if (data.code === STATUS_CODE.SUCCESS) {

        this.data = data.data

      }
      this.loading = false;
    },

    handleSizeChange(val) {
      this.$router.push({
        name: "notifi",
        query: { ...this.queryParam, ...{ size: val, page: 1 } },
      });
    },

    handleCurrentChange(val) {
      this.$router.push({
        name: "notifi",
        query: { ...this.queryParam, ...{ page: val } },
      });
    },

    clickSearch()
    {
      this.$router.push({
        name: "notifi",
        query: {...this.queryParam, ...this.form, ...{page: 1}}
      }).catch(err => {
        this.getList();
      });
    },

    getOverallIndex(index) {
      return (this.data.current_page * this.data.per_page) - this.data.per_page + (index + 1)
    },

    getDataFromQuery() {
      Object.keys(this.queryParam).map((value) => {
        this.form[value] = this.queryParam[value];
      });
    },

    deleteNotifi(val) {
      this.dialogDelete = true;
      this.id = val.id;
    },

   async comfirmDeleteNotifi(){
      const data = await this.$store.dispatch(DELETE_NOTIFI,{id: this.id} );
      if (data.code === STATUS_CODE.SUCCESS) {
        this.notifySuccess(this.$t("deleteSuccess"));
        this.dialogDelete = false;
        this.getList();
      }
    },

    getId(id){
      this.$router.push({ name: 'content-email',query :{ flag: 2, id: id}})
    },
  }

};
</script>
<style scoped>
.router-link{
  padding: 10px 10px !important;
  border: none !important;
}
::v-deep .search .el-form-item__content{
  width: 80%;
}
.send-email-all{
  background-color: #70B603 !important;
  margin-bottom: 10px;
}
.detail-email{
  background-color: #F59A23 !important;
}
.send-email{
  background-color: #02A7F0 !important;
}
.delete-btn{
  background: #D9001B !important;
}
.btn-cancel{
  background-color: #AAAAAA;
  color: white;
  border-color: #AAAAAA;
}
.dialog-delete .btn-cancel:hover{
  background-color: #AAAAAA;
  color: white;
  border-color: #AAAAAA;
}
.title{
 text-align: left;
 width: 100%;
}
.content{
  text-align: left;
  width: 100%;
}
::v-deep .el-dialog__header{
  padding-top: 50px;
}
</style>